import React from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import Member from '../../../models/member';
import { MemberSelector } from './MemberSelector';

interface AccountMemberSelectorProps {
  members: Member[];
  selectedMembers: { [key: string]: Member };
  onMemberSelected: Function;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    membersContainer: { display: 'flex', flexDirection: 'row', flexWrap: 'wrap' },
  }),
);

export const AccountMemberSelector: React.FC<AccountMemberSelectorProps> = ({
  members,
  selectedMembers,
  onMemberSelected,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.membersContainer}>
      {members.map((member: Member, index: number) => (
        <MemberSelector
          key={index}
          member={member}
          selectable={true}
          onMemberSelected={onMemberSelected}
          selectedMembers={selectedMembers}
        />
      ))}
    </Box>
  );
};
