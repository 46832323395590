import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import StatusBadge, { getStatusVariant } from '../StatusBadge';
import { removeUnderscoresFromString } from '../../lib/util';
import Member from '../../models/member';
import useGlobalStyles from '../../styles/globals';

const useStyles = makeStyles({
  row: {
    cursor: 'pointer',
    '& > *': {
      borderBottom: 'unset',
    },
  },
  childRow: {
    cursor: 'pointer',
  },
  emptyRow: {
    cursor: 'default',
  },
});

export default function AccountRow(props: any) {
  const { account, handleAccountRowClick } = props;
  const { members } = account;
  const [open, setOpen] = React.useState(false);
  const styles = useStyles();
  const globalStyles = useGlobalStyles();

  return (
    <>
      <TableRow className={styles.row} hover>
        <TableCell className={globalStyles.centeredCell}>
          <IconButton
            size="small"
            onClick={e => {
              e.stopPropagation();
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell onClick={handleAccountRowClick}>{account.accountNumber}</TableCell>
        <TableCell onClick={handleAccountRowClick} className={globalStyles.ellipsisCell}>
          {account.accountName}
        </TableCell>
        <TableCell onClick={handleAccountRowClick}>{account.certNumber || 'n/a'}</TableCell>
        <TableCell onClick={handleAccountRowClick}>{account.netsuiteId}</TableCell>
        <TableCell onClick={handleAccountRowClick}>
          {moment(account.started).format('MMMM D, YYYY')}
        </TableCell>
        <TableCell onClick={handleAccountRowClick}>
          <StatusBadge
            variant={getStatusVariant(account.status)}
            label={removeUnderscoresFromString(account.status)}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Paper elevation={3}>
              <Box margin={1} style={{ padding: 20 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Members
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Date of Birth</TableCell>
                      <TableCell>Member #</TableCell>
                      <TableCell>NetSuite Id</TableCell>
                      <TableCell>Dep #</TableCell>
                      <TableCell>Role</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!members.length && (
                      <TableRow className={`${styles.row} ${styles.emptyRow}`}>
                        <TableCell colSpan={8} className={globalStyles.centeredCell}>
                          No members found
                        </TableCell>
                      </TableRow>
                    )}
                    {members
                      .sort((a: Member, b: Member) => {
                        return a.dependentNumber < b.dependentNumber ? -1 : 1;
                      })
                      .map((member: any) => (
                        <TableRow key={member.uid} hover className={styles.childRow}>
                          <TableCell width={30}>
                            <Avatar style={{ backgroundColor: '#33acaa', color: '#ffffff' }}>
                              {member.firstName?.substring(0, 1)}
                              {member.lastName?.substring(0, 1)}
                            </Avatar>
                          </TableCell>
                          <TableCell>
                            {member.firstName} {member.lastName}
                          </TableCell>
                          <TableCell>
                            {moment(member.dob, 'YYYY-MM-DD').format('MMMM D, YYYY')}
                          </TableCell>
                          <TableCell>{member.memberNumber}</TableCell>
                          <TableCell>{member.netsuiteId}</TableCell>
                          <TableCell>{member.dependentNumber}</TableCell>
                          <TableCell>{removeUnderscoresFromString(member.role)}</TableCell>
                          <TableCell>
                            <StatusBadge
                              variant={getStatusVariant(member.status)}
                              label={removeUnderscoresFromString(member.status)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Paper>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
