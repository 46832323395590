import Organization from './organization';
import Group from './group';
import MembershipAccount from './membershipAccount';
import Person from './person';
import ProgramEnrollment from './programEnrollment';

export default interface Member {
  uid: string;
  organization: Organization | string;
  group: Group | string;
  membershipAccount: MembershipAccount | string;
  person: Person | string;
  programEnrollments?: ProgramEnrollment[];
  memberNumber: string;
  sfContactId?: string;
  sfContactUID?: string;
  netsuiteId?: string;
  ssn?: string;
  role: string;
  dependentNumber: string;
  retired?: boolean;
  settings: object;
  status: string;
  ended: string | null;
  archived?: boolean;
}

export enum Role {
  AccountOwner = 'account_owner',
  Admin = 'admin',
  Member = 'member',
}

export enum MemberStatus {
  Pending = 'pending',
  Active = 'active'
}