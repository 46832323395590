import filedownload from 'js-file-download';

import apiClient from './client';
import { APIClientBase } from './apiClientBase';

class BenefitsManagement extends APIClientBase {
  private static _instance: BenefitsManagement;

  static getInstance(): BenefitsManagement {
    if (BenefitsManagement._instance === undefined) {
      BenefitsManagement._instance = new BenefitsManagement();
    }
    return BenefitsManagement._instance;
  }

  getAllTransactions = async (query: any): Promise<any> => {
    try {
      const queryString = this.toQueryString(query);
      const url = `/membership/benefitsManagement/transactions${
        queryString ? `?${queryString}` : ''
      }`;
      const response = await apiClient.get(url);
      return this.response(response);
    } catch (err) {
      const error = this.error(err);
      throw error;
    }
  };

  /**
   * Send a request to start the EDI transaction process job in the benefits management service
   * @returns a message indicatiing that the job has started
   */
  processTransactions = async (): Promise<void> => {
    try {
      const url = `/membership/benefitsManagement/transactions`;
      const response = await apiClient.patch(url, { responseType: 'text' });
      return this.response(response);
    } catch (err) {
      const error = this.error(err);
      throw error;
    }
  };

  // get all the edi files
  getAllEDIFiles = async (query: any): Promise<any> => {
    const queryString = this.toQueryString(query);
    try {
      const url = `/membership/benefitsManagement/ediFiles${queryString ? `?${queryString}` : ''}`;
      const response = await apiClient.get(url);
      return this.response(response);
    } catch (err) {
      const error = this.error(err);
      throw error;
    }
  };

  downloadEDIFile = async (uid: string): Promise<any> => {
    try {
      const url = `/membership/benefitsManagement/ediFiles/${uid}/download`;
      const response = await apiClient.get(url, { responseType: 'text' });
      const result = this.response(response);
      filedownload(result.data, result.filename);
    } catch (err) {
      const error = this.error(err);
      throw error;
    }
  };

  /**
   * Makes a request to send the EDI file to the Eldorado FTP server
   * @param uid The EDI file uid
   * @returns A message indicating that the file has been sent
   */
  sendEDIFile = async (uid: string, query: any): Promise<any> => {
    const queryString = this.toQueryString(query);
    console.log(queryString);
    try {
      const url = `/membership/benefitsManagement/ediFiles/${uid}/send${
        queryString ? `?${queryString}` : ''
      }`;
      const response = await apiClient.get(url, { responseType: 'text' });
      return this.response(response);
    } catch (err) {
      const error = this.error(err);
      throw error;
    }
  };
}

export default BenefitsManagement.getInstance();
