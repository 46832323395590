import React from 'react';
import moment from 'moment';
import {
  Card,
  CardContent,
  Avatar,
  Typography,
  makeStyles,
  createStyles,
  Tooltip,
  Theme,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';

import { removeUnderscoresFromString, sortProgramEnrollments } from '../../../lib/util';
import Member from '../../../models/member';
import Person from '../../../models/person';
import StatusBadge, { getStatusVariant } from '../../StatusBadge';
import ProgramEnrollment from '../../../models/programEnrollment';

interface MemberCardProps {
  handleMemberOpen?: () => void;
  member: Member;
  programEnrollments: ProgramEnrollment[];
  saMemberProgramsUid: string[];
  showAvatar?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    memberCard: {
      width: '400px',
      opacity: 0.6,
      transition: 'all 0.2s ease-in-out',
      '&:hover': {
        opacity: 1,
      },
    },
    memberContent: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      height: '100px',
      paddingBottom: '16px',
      '& >:last-child': {
        marginRight: '12px',
        marginLeft: 'auto',
      },
    },
    memberInfoWrapper: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '20px',
      '& .badges': {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '5px',
        marginTop: '5px',
      },
      '& h6': {
        margin: 0,
        marginLeft: 'auto',
      },
    },

    archivedIcon: {
      position: 'absolute',
      marginTop: '-10px',
      marginRight: '-8px',
      top: 0,
      right: 0,
      color: theme.textColors.warn,
      opacity: 0.6,
      zIndex: 1000,
    },
  }),
);

/**
 * Gets the most recent program enrollment role of the member, or returns a badge with a Not Enrolled label
 * @param programEnrollments - the program enrollments of the member
 */
const getEnrollmentRole = (member: Member, programEnrollments: ProgramEnrollment[]) => {
  const recentProgramEnrollment = programEnrollments.sort(sortProgramEnrollments('DESC'))[0];
  if (recentProgramEnrollment) {
    return removeUnderscoresFromString(recentProgramEnrollment.role);
  }

  return member.dependentNumber === '00' ? 'Head of Household' : 'Not enrolled';
};

const MemberCard: React.FC<MemberCardProps> = ({
  handleMemberOpen,
  member,
  programEnrollments,
  saMemberProgramsUid,
  showAvatar = true,
}) => {
  const styles = useStyles();
  const memberPerson = member.person as Person;
  return (
    <div>
      {member.archived && (
        <div style={{ position: 'relative' }}>
          <Tooltip title="archived">
            <LockIcon className={styles.archivedIcon} />
          </Tooltip>
        </div>
      )}
      <Card className={styles.memberCard} onClick={handleMemberOpen || undefined}>
        <CardContent className={styles.memberContent}>
          {showAvatar && (
            <Avatar>
              {memberPerson.firstName.substring(0, 1)}
              {memberPerson.lastName.substring(0, 1)}
            </Avatar>
          )}
          <div className={styles.memberInfoWrapper}>
            <strong>
              {memberPerson.firstName} {memberPerson.lastName}
            </strong>
            <div>
              <small>
                {memberPerson.gender}, {moment().diff(memberPerson.dob, 'years')} years old
              </small>
            </div>
            <div className="badges">
              <StatusBadge
                variant={getStatusVariant(member.status)}
                label={removeUnderscoresFromString(member.status)}
              />
              {member.retired && <StatusBadge label="Retired" variant="info" />}
            </div>
          </div>
          <div className={styles.memberInfoWrapper}>
            <Typography variant="h6">{removeUnderscoresFromString(member.role)}</Typography>
            <Typography variant="h6">{getEnrollmentRole(member, programEnrollments)}</Typography>
            <Typography variant="h6">DEP {member.dependentNumber}</Typography>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default MemberCard;
