import Authentication, { AuthenticationResponse } from './authentication';
import Organizations from './organizations';
import Groups from './groups';
import Users from './users';
import MembershipAccounts from './membershipAccounts';
import Resources from './resources';
import Addresses from './addresses';
import Members from './members';
import MembershipPrograms, { ProgramChangePayload, ProgramUpdatePayload } from './memberPrograms';
import AuditEvents from './auditEvents';
import AppSettings from './appSettings';
import BenefitsManagement from './benefitsManagement';
import ExceptionLogs from './exceptionLogs';
import Jobs from './jobs';
import ProgramCatalog from './programCatalog';

class CCMCore {
  static authenticate = Authentication.authenticate;
  static organizations = Organizations;
  static groups = Groups;
  static users = Users;
  static membershipAccounts = MembershipAccounts;
  static membershipPrograms = MembershipPrograms;
  static resources = Resources;
  static addresses = Addresses;
  static auditEvents = AuditEvents;
  static members = Members;
  static appSettings = AppSettings;
  static benefitsManagement = BenefitsManagement;
  static exceptionLogs = ExceptionLogs;
  static programCatalog = ProgramCatalog;
  static jobs = Jobs;
}

export type { AuthenticationResponse, ProgramChangePayload, ProgramUpdatePayload };

export default CCMCore;
