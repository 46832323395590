import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Tab, Tabs, Theme, Typography } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import Spacer from '../../Spacer';
import { AccountMemberSelector } from './AccountMemberSelector';
import { AccountMemberList } from './AccountMemberList';
import MembershipAccount from '../../../models/membershipAccount';
import Member from '../../../models/member';
import { MAX_CCM_PROGRAM_DATE } from '../../../lib/util';
import ProgramEnrollment from '../../../models/programEnrollment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    tab: {
      flex: 1,
      paddingLeft: 20,
    },
    endDate: {
      marginTop: '.6rem',
      marginBottom: '.6rem',
    },
    notice: {
      marginBottom: '.6rem',
    },
  }),
);

interface MembershipWithdrawalFormProps {
  currentTab: number;
  membershipAccount: MembershipAccount;
  onTabClick: Function;
  selectedMembers: { [key: string]: Member };
  onMemberSelected: Function;
  onDateSelected: Function;
  endDate: string;
  earliestWithdrawalDate: string;
}

const MembershipWithdrawalForm: React.FC<MembershipWithdrawalFormProps> = ({
  currentTab,
  membershipAccount,
  onTabClick,
  selectedMembers,
  onMemberSelected,
  onDateSelected,
  endDate,
  earliestWithdrawalDate,
}) => {
  const handleTabChange = (event: React.ChangeEvent<{}>, tabIndex: number) => {
    onTabClick(tabIndex);
  };

  const classes = useStyles();
  const [withdrawableMembers, setWithdrawableMembers] = useState<Member[]>([]);

  useEffect(() => {
    const members = membershipAccount.members!;

    const activeAndPendingEnrollments = membershipAccount.programEnrollments!.filter(pe =>
      members.some(m => {
        if (typeof pe.member === 'string') {
          return (
            pe.member === m.uid &&
            ((pe.ended && moment(pe.ended).isAfter(new Date())) ||
              pe.ended === null ||
              (pe.started && moment(pe.started).isAfter(new Date())))
          );
        }
        return (
          (pe.member as Member).uid === m.uid &&
          ((pe.ended && moment(pe.ended).isAfter(new Date())) ||
            pe.ended === null ||
            (pe.started && moment(pe.started).isAfter(new Date())))
        );
      }),
    );

    const enrolledMemberIds = activeAndPendingEnrollments.map((enrollment: ProgramEnrollment) =>
      typeof enrollment.member === 'string' ? (enrollment.member as string) : enrollment.member.uid,
    );

    const activeAndPendingMembers = members?.filter((member: Member) =>
      enrolledMemberIds.includes(member.uid),
    );

    setWithdrawableMembers(activeAndPendingMembers.filter((m: Member) => !m.ended));
  }, [membershipAccount.members, membershipAccount.programEnrollments]);

  return (
    <div
      style={{
        flexGrow: 1,
        display: 'flex',
        height: 700,
        width: 1200,
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={currentTab}
        onChange={handleTabChange}
        className={classes.tabs}
        indicatorColor="primary"
        aria-label="Membership withdrawal tabs"
      >
        <Tab label="Members" id="vertical-tab-0" />
        <Tab
          label="Confirmation"
          id="vertical-tab-1"
          disabled={Object.values(selectedMembers).length < 1}
        />
      </Tabs>
      <div role="tabpanel" className={classes.tab} hidden={currentTab !== 0}>
        <Typography variant="body1" className={classes.notice}>
          Choose which members to withdraw and choose the last day of enrollment. Program coverage
          will remain effective through 11:59:59 PM on the end date chosen.
        </Typography>

        <Spacer size={20} />

        <Typography variant="h2">Enrollment End Date</Typography>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            //clearable this is causing error on the UI; disabled for now
            value={moment(endDate).toDate()}
            inputVariant="outlined"
            variant="inline"
            onChange={date => onDateSelected(date)}
            format="MM/dd/yyyy"
            label="End Date"
            className={classes.endDate}
            minDate={moment(earliestWithdrawalDate)}
            maxDate={moment(MAX_CCM_PROGRAM_DATE)}
            minDateMessage={`The end date cannot be before ${moment(earliestWithdrawalDate).format(
              'LL',
            )}`}
            maxDateMessage={`The end date cannot be after ${moment(MAX_CCM_PROGRAM_DATE).format(
              'LL',
            )}`}
          />
        </MuiPickersUtilsProvider>

        <Spacer size={20} />

        <Typography variant="h2">Members</Typography>
        {withdrawableMembers.length < 1 && (
          <Alert severity="warning">There are no withdrawable members</Alert>
        )}

        {withdrawableMembers.length > 0 && (
          <AccountMemberSelector
            selectedMembers={selectedMembers}
            members={withdrawableMembers}
            onMemberSelected={onMemberSelected}
          />
        )}
      </div>
      <div role="tabpanel" className={classes.tab} hidden={currentTab !== 1}>
        <Typography variant="body1" className={classes.notice}>
          Click <span style={{ fontWeight: 'bold' }}>Confirm</span> to withdraw these members from
          all active program enrollments.
        </Typography>

        <Spacer size={20} />

        <Typography variant="h2">Enrollment End Date</Typography>
        <Typography variant="h4">{moment(endDate).format('MMMM D, YYYY')}</Typography>

        <Spacer size={20} />
        <Typography variant="h2">Members</Typography>
        <AccountMemberList members={Object.values(selectedMembers)} />
      </div>
    </div>
  );
};

export default MembershipWithdrawalForm;
