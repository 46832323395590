import React from 'react';
import Member from '../../../models/member';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { MemberSelector } from './MemberSelector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    membersContainer: { display: 'flex', flexDirection: 'row' },
  }),
);

export const AccountMemberList: React.FC<{ members: Member[] }> = ({ members }) => {
  const classes = useStyles();

  return (
    <Box className={classes.membersContainer}>
      {members.map((member: Member, index: number) => (
        // <Grid item lg={6} xs={12} key={index}>
        //   <SelectedMember member={member} />
        // </Grid>
        <MemberSelector key={index} member={member} selectable={false} />
      ))}
    </Box>
  );
};
