import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  createStyles,
  FormControlLabel,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';

import Member from '../../../models/member';
import Person from '../../../models/person';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontWeight: 'bold',
      marginRight: '3px',
    },
    memberCard: { minWidth: '320px', margin: '0 1rem 1rem 0' },
    cardContent: {
      opacity: 0.6,
      transition: 'all 0.2s ease-in-out',
      '&:hover': {
        opacity: 1,
      },
      backgroundColor: theme.backgroundColors.body,
      padding: '4px 12px 12px!important',
      margin: 0,
    },
    checkBoxLabel: {
      marginBottom: '0',
    },
    memberName: {
      marginTop: '4px',
      fontWeight: 'bold',
    },
  }),
);

interface MemberSelectorProps {
  member: Member;
  selectable: boolean;
  onMemberSelected?: Function;
  selectedMembers?: { [key: string]: Member };
}

const MemberName = ({ member, isCheckboxLabel }: { member: Member; isCheckboxLabel?: boolean }) => {
  const classes = useStyles();

  return (
    <Typography
      variant="body1"
      className={clsx([classes.memberName, isCheckboxLabel && classes.checkBoxLabel])}
    >
      {(member.person as Person).firstName} {(member.person as Person).lastName}
    </Typography>
  );
};

export const MemberSelector: React.FC<MemberSelectorProps> = ({
  member,
  selectable,
  onMemberSelected,
  selectedMembers,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.memberCard}>
      <CardContent className={classes.cardContent}>
        <Box>
          {selectable && (
            <FormControlLabel
              labelPlacement="end"
              control={
                <Checkbox
                  name="memberSelector"
                  checked={selectedMembers && selectedMembers.hasOwnProperty(member.uid)}
                  color="primary"
                  onChange={event =>
                    onMemberSelected && onMemberSelected(event.target.checked, member)
                  }
                />
              }
              label={<MemberName member={member} isCheckboxLabel={true} />}
            />
          )}
          {!selectable && <MemberName member={member} />}
        </Box>
        <Box>
          <div>
            <span className={classes.label}>Dependent #:</span>
            {member.dependentNumber}
          </div>
          <div>
            <span className={classes.label}>Role:</span>
            {member.role.replaceAll('_', ' ')}
          </div>
        </Box>
      </CardContent>
    </Card>
  );
};
