import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statusBadge: {
      cursor: 'default',
      display: 'inline-flex',
      padding: '2px 8px',
      flexGrow: 0,
      fontSize: '0.75rem',
      minWidth: '20px',
      alignItems: 'center',
      flexShrink: 0,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      whiteSpace: 'nowrap',
      borderRadius: '4px',
      letterSpacing: '0.5px',
      textTransform: 'uppercase',
      justifyContent: 'center',
    },
    primary: {
      color: theme.palette.type === 'dark' ? '#2a3eb1' : '#ffffff',
      backgroundColor: theme.palette.type === 'dark' ? 'rgba(42, 62, 177, 0.08)' : '#2a3eb1',
    },
    success: {
      color: theme.palette.type === 'dark' ? '#4caf50' : '#ffffff',
      backgroundColor: theme.palette.type === 'dark' ? 'rgba(76, 175, 80, 0.08)' : '#4caf50',
    },
    info: {
      color: theme.palette.type === 'dark' ? '#04a7ed' : '#ffffff',
      backgroundColor: theme.palette.type === 'dark' ? 'rgba(4, 167, 237, 0.08)' : '#04a7ed',
    },
    warn: {
      color: theme.palette.type === 'dark' ? '#ff9800' : '#ffffff',
      backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 152, 0, 0.08)' : '#ff9800',
    },
    danger: {
      color: theme.palette.type === 'dark' ? '#f44336' : '#ffffff',
      backgroundColor: theme.palette.type === 'dark' ? 'rgba(244, 67, 54, 0.08)' : '#f44336',
    },
    default: {
      color: theme.palette.type === 'dark' ? '#e0e0e0' : '#333333',
      backgroundColor: theme.palette.type === 'dark' ? 'rgba(204, 204,204, 0.08)' : '#e0e0e0',
    },
    secondary: {
      color: theme.palette.type === 'dark' ? '#9d50f4' : '#ffffff',
      backgroundColor: theme.palette.type === 'dark' ? 'rgba(157, 80, 244, 0.08)' : '#9d50f4',
    },
    button: {
      cursor: 'pointer',
      userSelect: 'none',
    },
  }),
);

export const getStatusVariant = (status: string): string => {
  let variant: string = '';
  switch (status.toLowerCase()) {
    case 'active':
      variant = 'success';
      break;
    case 'pending':
      variant = 'info';
      break;
    case 'suspended':
      variant = 'warn';
      break;
    case 'cancelled':
    case 'deleted':
      variant = 'danger';
      break;
    case 'inactive':
    default:
      variant = 'default';
  }

  return variant;
};

const StatusBadge = (props: any) => {
  const { label, variant, asButton, ...rest } = props;
  const styles = useStyles();

  const getStatusStyle = (variant: string): string => {
    switch (variant) {
      case 'primary':
        return styles.primary;
      case 'secondary':
        return styles.secondary;
      case 'success':
        return styles.success;
      case 'info':
        return styles.info;
      case 'warn':
        return styles.warn;
      case 'danger':
        return styles.danger;
      case 'default':
      default:
        return styles.default;
    }
  };

  return (
    <Box
      className={`${styles.statusBadge} ${getStatusStyle(variant)} ${asButton && styles.button}`}
      {...rest}
    >
      {label}
    </Box>
  );
};

export default StatusBadge;
